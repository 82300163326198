import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

import './App.css';
import Navbar from './components/navbar/Navbar';
import Home from './pages/home/HomePage';
import Investors from './pages/investors/InvestorsPage';
import ContactPage from './pages/contact/ContactPage';

const App = () => {
    const location = useLocation();

    return (
        <div className='App'>
            {location.pathname !== '/contact' && <Navbar />}
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/investors" element={<Investors />} />
                <Route path="/contact" element={<ContactPage />} />
            </Routes>
        </div>
    );
};

const AppWrapper = () => (
    <Router>
        <App />
    </Router>
);

export default AppWrapper;
