import React from 'react';
import styles from './SuccessForm.module.css';
import logo from '../../assets/img/logo.svg'
import arrow from "../../assets/img/keyboard_double_arrow_right.svg";
import {useNavigate} from "react-router-dom";
const SuccessForm = () => {
    const navigate = useNavigate();
    return (
        <div className={styles.container}>
            <div className={styles.messageBox}>
                <div className={styles.logo}><img src={logo}/></div>
                <h1 className={styles.header}>Thank you for registering.</h1>
                <p className={styles.text}>Keep an eye on your mailbox!</p>
                <button className={styles.button} onClick={() => navigate('/')}>
                    <p>Back to Home Page</p>

                </button>
            </div>
        </div>
    );
};

export default SuccessForm;
