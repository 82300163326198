import React, { useState, useEffect, useRef } from 'react';
import styles from './HomePage.module.css';
import arrow from '../../assets/img/keyboard_double_arrow_right.svg';
import arrow_down from '../../assets/img/keyboard_arrow_down.svg';
import marketingImage from '../../assets/img/Marketing.svg';
import gamingImage from '../../assets/img/Gaming.svg';
import filmsImage from '../../assets/img/Films.svg';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState('Marketing');
    const dropdownRef = useRef(null);

    const handleTabClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedTab(option);
        setIsDropdownOpen(false);
    };

    const getImageForTab = (tab) => {
        switch (tab) {
            case 'Marketing':
                return marketingImage;
            case 'Gaming':
                return gamingImage;
            case 'Films':
                return filmsImage;
            default:
                return marketingImage;
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        if (isDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownOpen]);

    return (
        <div className={styles.container}>
            <div className={styles.background_stripes}>
                <div className={styles.stripe}></div>
                <div className={styles.stripe}></div>
                <div className={styles.stripe}></div>
                <div className={styles.stripe}></div>
            </div>
            <h1 className={styles.header}>Write Less. Create More. The Future of Writing is Here.</h1>
            <p className={styles.text}>
                Unlock effortless writing with our AI-powered co-pilot tool, in a fraction of the time, and discover a
                new world of limitless creativity and productivity.
            </p>
            <button className={styles.button} onClick={() => navigate('/contact')}>
                <p>Join the Waitlist Today</p>
                <img src={arrow} alt="arrow"/>
            </button>
            <div className={styles.flex}>
                <div className={styles.text_container} ref={dropdownRef}>
                    <p className={styles.bold_text}>Find solutions for writers in </p>
                    <div className={styles.tab_container} onClick={handleTabClick}>
                        <p className={styles.pink_text}>{selectedTab}</p>
                        <img src={arrow_down} alt="arrow down"/>
                    </div>
                    {isDropdownOpen && (
                        <div className={styles.dropdown}>
                            <p className={styles.dropdown_option}
                               onClick={() => handleOptionClick('Marketing')}>Marketing</p>
                            <p className={styles.dropdown_option} onClick={() => handleOptionClick('Gaming')}>Gaming</p>
                            <p className={styles.dropdown_option} onClick={() => handleOptionClick('Films')}>Films</p>
                        </div>
                    )}
                </div>
                <div className={styles.image_container}>
                    <img src={getImageForTab(selectedTab)} alt="Diagram" className={styles.image}/>
                </div>
            </div>

        </div>
    );
};

export default Home;
