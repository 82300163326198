import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Navbar.module.css';
import logo from '../../assets/img/logo.svg'
const Navbar = () => {
    const navigate = useNavigate();

    return (
        <nav className={styles.navbar}>
            <div className={styles.logo_container}>
                <img style={{cursor: 'pointer'}} src={logo} onClick={() => navigate('/')}/>
                <p>AIOHub</p>
            </div>

            <div className={styles.navLinks}>
            <button className={styles.link} onClick={() => navigate('/')}>Home</button>
                <button className={styles.link} onClick={() => navigate('/investors')}>Investors</button>
            </div>
        </nav>
    );
};

export default Navbar;
