import React, { useState } from 'react';
import {  collection, addDoc  } from 'firebase/firestore/lite';
import styles from './ContactPage.module.css';
import SuccessForm from './SuccessForm';
import logo from '../../assets/img/logo.svg';
import { db } from '../../shared/db/firebase';
import LoadingComponent from "../../shared/ui/loading/LoadingComponent";
import {useNavigate} from "react-router-dom"; // Ensure correct path to your firebase configuration

const ContactPage = () => {
    const [formData, setFormData] = useState({
        email: '',
        fullName: '',
        preferredName: '',
        companyName: '',
        jobTitle: ''
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const contactsCol = collection(db, 'contacts');
            const docRef = await addDoc(contactsCol, {
                email: formData.email,
                fullName: formData.fullName,
                preferredName: formData.preferredName,
                companyName: formData.companyName,
                jobTitle: formData.jobTitle,
                date:Date.now()
            });


            setIsLoading(false);
            setIsSubmitted(true);
        } catch (error) {
            setIsLoading(false);
            alert('Something went wrong. Please try again.');
            console.error('Error adding document: ', error);
        }
    };

    if (isSubmitted) {
        return <SuccessForm />;
    }

    return (
        <div className={styles.container}>
            <div className={styles.formContainer}>
                <div className={styles.logo}>
                    <img src={logo} alt="Logo" onClick={() => navigate('/')}/>
                </div>
                <h1 className={styles.header}>Thank you for your interest in AIOhub</h1>
                <form className={styles.form} onSubmit={handleSubmit}>
                    <label className={styles.label} htmlFor="email">
                        Email *
                    </label>
                    <input
                        className={styles.input}
                        type="email"
                        id="email"
                        name="email"
                        placeholder="example@email.com"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />

                    <label className={styles.label} htmlFor="fullName">
                        Full Name *
                    </label>
                    <input
                        className={styles.input}
                        type="text"
                        id="fullName"
                        name="fullName"
                        placeholder="Enter full name"
                        value={formData.fullName}
                        onChange={handleChange}
                        required
                    />

                    <label className={styles.label} htmlFor="preferredName">
                        What would you like to be called as? (Ex: When we email) *
                    </label>
                    <input
                        className={styles.input}
                        type="text"
                        id="preferredName"
                        name="preferredName"
                        placeholder="Enter preferred name"
                        value={formData.preferredName}
                        onChange={handleChange}
                        required
                    />

                    <label className={styles.label} htmlFor="companyName">
                        Company Name *
                    </label>
                    <input
                        className={styles.input}
                        type="text"
                        id="companyName"
                        name="companyName"
                        placeholder="Enter company name"
                        value={formData.companyName}
                        onChange={handleChange}
                        required
                    />

                    <label className={styles.label} htmlFor="jobTitle">
                        Job Title *
                    </label>
                    <input
                        className={styles.input}
                        type="text"
                        id="jobTitle"
                        name="jobTitle"
                        placeholder="Enter job title"
                        value={formData.jobTitle}
                        onChange={handleChange}
                        required
                    />

                    <button className={styles.button} type="submit" disabled={isLoading}>
                        {isLoading ? 'Submitting...' : 'Submit Form'}
                    </button>
                </form>
            </div>
            {isLoading&&<LoadingComponent/>}

        </div>
    );
};

export default ContactPage;
