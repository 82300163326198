import React from 'react';
import s from './LoadingComponent.module.css'
import loading from '../../../assets/img/search.gif'
const LoadingComponent = () => {
    return (
        <div className={s.container}>
            <img src={loading} className={s.loading}/>

        </div>
    );
};

export default LoadingComponent;
